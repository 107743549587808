// PoseEstimationPage.js
import React, { useState, useEffect} from 'react';
import {
    Box, Tab, Tabs, Typography, Button,
  } from '@mui/material';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import runningImage from './running_direction.webp'; 
import cyclingImage from './cycling_direction.webp'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

function PoseEstimationPage() {

  const styles = {
    pageContainer: {
    backgroundColor: '#f5f5f7', // Light gray background
    minHeight: '100vh',
    padding: '2rem',
    },
    contentCard: {
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
    },
    tabs: {
    marginBottom: '2rem',
    '& .MuiTab-root': {
        fontSize: '1.1rem',
        fontWeight: 500,
        minWidth: '160px',
        borderRadius: '8px 8px 0 0',
    },
    '& .Mui-selected': {
        backgroundColor: '#ffffff',
        color: '#1976d2',
    },
    },
    sectionCard: {
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    padding: '1.5rem',
    marginBottom: '2rem',
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    },
    },
    sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#1a1a1a',
    marginBottom: '1.5rem',
    },
    formControl: {
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    },
    actionButton: {
    borderRadius: '8px',
    padding: '0.75rem 2rem',
    fontWeight: 600,
    textTransform: 'none',
    },
};
    const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleTabChange = (event, newValue) => {
      setCurrentSubTabIndex(newValue);
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };

    const [isLoading, setIsLoading] = useState(false);
    
    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

    const [result, setResult] = useState("");
    const [timer, setTimer] = useState(0);
    
  const handleUploadRunning = async () => {
    if (!selectedFile) {
      alert("Please select a video file first!");
      return;
    }
    
    try {

      // Check if the video duration is within the limit
      await checkVideoDuration(selectedFile);
      
      setIsLoading(true); // Start loading
      const formData = new FormData();
      formData.append("file", selectedFile);
      // Send the video file to the backend for immediate processing
      const response = await fetch(`${apiUrl}/estimate_pose_running`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setResult(data.result); // Display the result of pose estimation
  
        // Handle the download of the processed video
        if (downloadVideoChecked && data.processedFilePath) {
          const filename = data.processedFilePath.split('/').pop(); // Extract filename
          const processedVideoUrl = `${apiUrl}/download/${filename}`;
          downloadVideo(processedVideoUrl);
        }
      } else {
        alert("Error processing video");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the file.");
    }finally {
      setIsLoading(false); // End loading
    }
  };

  const handleUploadRoadCycling = async () => {
    if (!selectedFile) {
      alert("Please select a video file first!");
      return;
    }
    
    try {

      // Check if the video duration is within the limit
      await checkVideoDuration(selectedFile);
      
      setIsLoading(true); // Start loading
      const formData = new FormData();
      formData.append("file", selectedFile);
      // Send the video file to the backend for immediate processing
      const response = await fetch(`${apiUrl}/estimate_pose_cycling`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setResult(data.result); // Display the result of pose estimation
  
        // Handle the download of the processed video
        if (downloadVideoChecked && data.processedFilePath) {
          const filename = data.processedFilePath.split('/').pop(); // Extract filename
          const processedVideoUrl = `${apiUrl}/download/${filename}`;
          downloadVideo(processedVideoUrl);
        }
      } else {
        alert("Error processing video");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the file.");
    }finally {
      setIsLoading(false); // End loading
    }
  };

  const handleUploadTTCycling = async () => {
    if (!selectedFile) {
      alert("Please select a video file first!");
      return;
    }
    
    try {

      // Check if the video duration is within the limit
      await checkVideoDuration(selectedFile);
      
      setIsLoading(true); // Start loading
      const formData = new FormData();
      formData.append("file", selectedFile);
      // Send the video file to the backend for immediate processing
      const response = await fetch(`${apiUrl}/estimate_pose_tt_cycling`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setResult(data.result); // Display the result of pose estimation
  
        // Handle the download of the processed video
        if (downloadVideoChecked && data.processedFilePath) {
          const filename = data.processedFilePath.split('/').pop(); // Extract filename
          const processedVideoUrl = `${apiUrl}/download/${filename}`;
          downloadVideo(processedVideoUrl);
        }
      } else {
        alert("Error processing video");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the file.");
    }finally {
      setIsLoading(false); // End loading
    }
  };

  const [downloadVideoChecked, setDownloadVideoChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setDownloadVideoChecked(event.target.checked);
  };

  const downloadVideo = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // The download attribute can be left empty or set to a specific filename
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
    useEffect(() => {
      let interval = null;
    
      if (isLoading) {
        interval = setInterval(() => {
          setTimer((prevTime) => prevTime + 1); // Increment the timer
        }, 1000);
      } else if (!isLoading && timer !== 0) {
        clearInterval(interval);
        setTimer(0); // Reset the timer
      }
    
      return () => clearInterval(interval);
    }, [isLoading, timer]);
  
  const checkVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
  
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        if (duration > 10) {
          reject("Video duration exceeds 10 seconds.");
          alert("Upload a video under 10 seconds.");
        } else {
          resolve();
        }
      };
  
      video.onerror = function() {
        reject("Error loading video file.");
      };
  
      video.src = URL.createObjectURL(file);
    });
  };
  
  return (
    <Box sx={styles.pageContainer}>
            <Box sx={styles.contentCard}>
                <Tabs
                value={currentSubTabIndex}
                onChange={handleTabChange}
                sx={styles.tabs}
                variant="scrollable" 
                scrollButtons="auto"
                centered
              >
                <Tab 
                    label={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <DirectionsRunIcon />
                        <Typography>Run</Typography>
                    </Stack>
                    }
                />
                <Tab 
                    label={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <DirectionsBikeIcon />
                        <Typography>Road Bike</Typography>
                    </Stack>
                    }
                />
                <Tab 
                    label={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <DirectionsBikeIcon />
                        <Typography>TT Bike</Typography>
                    </Stack>
                    }
                />
                </Tabs>

          {currentSubTabIndex === 0 && (
            <Box sx={{ p: 3 }}>
              <Stack spacing={2}>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadRunning}
                  disabled={isLoading}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 180, // Set a fixed width for the button
                  }}
                >
                  {isLoading ? (
                    <React.Fragment>
                      <CircularProgress size={24} color="inherit" />
                      <Typography variant="button" sx={{ ml: 1 }}>
                        Loading... {timer} sec
                      </Typography>
                    </React.Fragment>
                  ) : (
                    "Estimate Pose"
                  )}
                </Button>
                <FormControlLabel
                  control={<Checkbox checked={downloadVideoChecked} onChange={handleCheckboxChange} />}
                  label="Download Processed Video (.mp4)"
                />
                <Box sx={{ marginY: 2, color: 'text.secondary' }}>
                  <Typography variant="body2" gutterBottom>
                    Please be aware:
                  </Typography>
                  <List dense>
                  <ListItem>
                      <ListItemText
                        primary="1. This model gives tips for the optimal long distance running, not sprints."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="2. Ensure that when you film yourself running, you move in the same direction as shown in the image below."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="3. The AI model yields the most accurate results when the runner is dressed in shorts and short sleeves."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="4. Video analysis may take up to 45-60 seconds."
                      />
                    </ListItem>
                  </List>
                </Box>
                {/* Conditional rendering of an image */}
                {!result && (
                  <img
                    src={runningImage}// Replace with the path to your image
                    alt="Image with a cyclist"
                    style={{
                      width: '50%', // 2/3 of the original width
                    }}
                  />
                )}
                
              </Stack>
              {result && (
                <Card sx={{ marginTop: 2 }}>
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      Video Analysis Result
                    </Typography>
                    {result.split('\n').map((line, index) => (
                      <Typography key={index} variant="body1">
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              )}
            </Box>
          )}
          {currentSubTabIndex === 1 && (
            <Box sx={{ p: 3 }}>
              <Stack spacing={2}>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadRoadCycling}
                  disabled={isLoading}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 180, // Ensure this matches the width in the running tab section
                  }}
                >
                  {isLoading ? (
                    <React.Fragment>
                      <CircularProgress size={24} color="inherit" />
                      <Typography variant="button" sx={{ ml: 1 }}>
                        Loading... {timer} sec
                      </Typography>
                    </React.Fragment>
                  ) : (
                    "Estimate Pose"
                  )}
                </Button>
                <FormControlLabel
                  control={<Checkbox checked={downloadVideoChecked} onChange={handleCheckboxChange} />}
                  label="Download Processed Video (.mp4)"
                />
                <Box sx={{ marginY: 2, color: 'text.secondary' }}>
                  <Typography variant="body2" gutterBottom>
                    Please be aware:
                  </Typography>
                  <List dense>
                  <ListItem>
                      <ListItemText
                        primary="1. The suggestions from the model "
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="2. Ensure that when you film yourself cycling, you move in the same direction as shown in the image below."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="3. The AI model yields the most accurate results when the cyclist is dressed in shorts and short sleeves."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="4. Video analysis may take up to 45-60 seconds."
                      />
                    </ListItem>
                  </List>
                </Box>
                {/* Conditional rendering of an image */}
                {!result && (
                  <img
                    src={cyclingImage}// Replace with the path to your image
                    alt="Image with a cyclist"
                    style={{
                      width: '50%', // 2/3 of the original width
                    }}
                  />
                )}
              </Stack>
              {result && (
                <Card sx={{ marginTop: 2 }}>
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      Video Analysis Result
                    </Typography>
                    {result.split('\n').map((line, index) => (
                      <Typography key={index} variant="body1">
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              )}
            </Box>
          )}
          {currentSubTabIndex === 2 && (
            <Box sx={{ p: 3 }}>
              <Stack spacing={2}>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadTTCycling}
                  disabled={isLoading}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 180, // Ensure this matches the width in the running tab section
                  }}
                >
                  {isLoading ? (
                    <React.Fragment>
                      <CircularProgress size={24} color="inherit" />
                      <Typography variant="button" sx={{ ml: 1 }}>
                        Loading... {timer} sec
                      </Typography>
                    </React.Fragment>
                  ) : (
                    "Estimate Pose"
                  )}
                </Button>
                <FormControlLabel
                  control={<Checkbox checked={downloadVideoChecked} onChange={handleCheckboxChange} />}
                  label="Download Processed Video (.mp4)"
                />
                <Box sx={{ marginY: 2, color: 'text.secondary' }}>
                  <Typography variant="body2" gutterBottom>
                    Please be aware:
                  </Typography>
                  <List dense>
                  <ListItem>
                      <ListItemText
                        primary="1. The suggestions from the model "
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="2. Ensure that when you film yourself cycling, you move in the same direction as shown in the image below, stay in aero position."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="3. The AI model yields the most accurate results when the cyclist is dressed in shorts and short sleeves."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="4. Video analysis may take up to 45-60 seconds."
                      />
                    </ListItem>
                  </List>
                </Box>
                {/* Conditional rendering of an image */}
                {!result && (
                  <img
                    src={cyclingImage}// Replace with the path to your image
                    alt="Image with a cyclist"
                    style={{
                      width: '50%', // 2/3 of the original width
                    }}
                  />
                )}
              </Stack>
              {result && (
                <Card sx={{ marginTop: 2 }}>
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      Video Analysis Result
                    </Typography>
                    {result.split('\n').map((line, index) => (
                      <Typography key={index} variant="body1">
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              )}
            </Box>
          )}
        </Box>
    </Box>
  );
}

export default PoseEstimationPage;
