// NutritionCalculation.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box, Tab, Tabs, Typography, TextField, Button, Grid,
    MenuItem, FormControl, InputLabel
} from '@mui/material';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { format, parseISO, isValid } from 'date-fns';

function NutritionCalculationPage() {


    const formatToHHMMSS = () => new Date(0).toISOString().substring(11, 19); // Extracts HH:mm:ss

    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable
    const [errorMessage, setErrorMessage] = useState("");
    const [exportFormat, setExportFormat] = useState('pdf');
    const [bikeTime, setBikeTime] = useState(formatToHHMMSS());
    const [runTime, setRunTime] = useState(formatToHHMMSS());
    const [distanceType, setDistanceType] = useState('');
    //const [selectedSex, setSelectedSex] = useState(''); // Or initial value
    const [selectedWeight, setSelectedWeight] = useState(''); // Or initial value
    const [sweatRate, setSweatRate] = useState(''); // Or initial value
    const [saltRate, setSaltRate] = useState(''); // Or initial value
    const [effort, setEffort] = useState(''); // Or initial value
    const [selectedMisc, setMiscellaneous] = useState(''); // Or initial value
    const [selectedDrinks, setDrinks] = useState(''); // Or initial value
    const [selectedBars, setBars] = useState(''); // Or initial value
    const [selectedGels, setGels] = useState(''); // Or initial value
  
    const animatedComponents = makeAnimated();


    const navigate = useNavigate(); // Get the navigate function
    const location = useLocation();
    const [dynamicLink, setDynamicLink] = useState("");

    const exportOptions  = [
        { value: 'pdf', label: 'pdf' }
        // { value: 'html', label: 'html' },
        // Add more options as needed
    ];

    const distanceTriathlonOptions  = [
        { value: 'long_distance_triathlon', label: 'Long Distance' },
        { value: 'middle_distance_triathlon', label: 'Middle Distance' }
    ];

    const distanceRunOptions  = [
        { value: 'marathon', label: 'Marathon' },
        { value: 'half_marathon', label: 'Half Marathon' }
        // { value: 'html', label: 'html' },
        // Add more options as needed
    ];

    const sweatOptions  = [
        { value: 'LOW', label: 'Low (0.8 L/h)' },
        { value: 'MODERATE', label: 'Moderate (0.8-1.4 L/h)' },
        { value: 'HIGH', label: 'High (1.4-2.0 L/h)' },
        { value: 'VERY_HIGH', label: 'Very High (>2 L/h)' }
        // Add more options as needed
    ];

      // Improved Time Parsing and Formatting Functions
    const parseTimeToTicks = (timeString) => {
        if (!timeString) return null;
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours || 0, minutes || 0, seconds || 0, 0);
        return date;
    };

    const sodiumOptions = [
        { value: 'LOW', label: 'Low (500 mg/L) - No salt marks' },
        { value: 'MODERATE', label: 'Moderate (750 mg/L) - Light salt marks' },
        { value: 'HIGH', label: 'High (1000 mg/L) - Noticeable salt marks' },
        { value: 'VERY_HIGH', label: 'Very High (1500 mg/L) - Heavy salt crusts' },
        // Add more options as needed
    ];

    const effortOptions  = [
        { value: 'EASY', label: 'Easy' },
        { value: 'MODERATE', label: 'Moderate' },
        { value: 'HARD', label: 'High' },
        // Add more options as needed
    ];

    const gelOptions = [
        { value: 'ph_30', label: 'PF 30 Gel' },
        { value: 'ph_30_caf', label: 'PF 30 Caffeine Gel' },
        { value: 'maurten_gel_100', label: 'Maurten Gel 100' },
        { value: 'maurten_gel_160', label: 'Maurten Gel 160' },
        { value: 'maurten_gel_100_caf', label: 'Maurten Gel 100 Caf' },
        { value: 'powerbar', label: 'Powerbar' },
        { value: 'powerbar_caf', label: 'Powerbar caffeinated' },
        // Add more options as needed
    ];

    const barOptions = [
        { value: 'endurance_fuel_choco', label: 'ENDURANCE FUEL BAR BCAA Dark Chocolate' },
        { value: 'endurance_fuel_banana', label: 'ENDURANCE FUEL BAR BCAA Banana Ginger' },
        { value: 'endurance_fuel_apple', label: 'ENDURANCE FUEL BAR BCAA Apple Cinnamon' },
        // Add more options as needed
    ];
    
    const drinkOptions = [
        { value: 'mortal', label: 'MORTAL HYDRATION Salty mix (650 ml)' },
        { value: 'precision_500', label: 'Precision PH 500 (750 ml)' },
        { value: 'precision_1000', label: 'Precision PH 1000 (750 ml)' },
        { value: 'precision_1500', label: 'Precision PH 1500 (750 ml)' },
        { value: 'gatorate_endurance', label: 'Gatorade Endurance (500 ml)' },
        { value: 'gatorate_thirst', label: 'Gatorade Thirst Quencher (1000 ml)' },
        { value: 'powerade', label: 'Powerade ION4 (750 ml)' }
        // Add more options as needed
    ];

    const miscOptions = [
        { value: 'pf_30_chew', label: 'Banana (medium)' },
        { value: 'banana', label: 'PF 30 Chew' },
        { value: 'gummy_bears', label: 'Gummy Bears (40g)' },
        { value: 'dates', label: 'Date (1 unit)' },
        { value: 'dried_fruits', label: 'Dried Mixed Fruits (27g)' },
        { value: 'pretzel_twists', label: 'Pretzel twists (10 units)' },
        { value: 'peanut_butter_sandwich', label: 'Peanut Butter Sandwich (93 g)'},
        { value: 'peanut_butter_jelly_sandwich', label: 'Peanut Butter Jelly Sandwich (102 g)'}
        // Add more options as needed
    ];

    const styles = {
        pageContainer: {
            backgroundColor: '#f5f5f7',
            minHeight: '100vh',
            padding: { xs: '1rem', sm: '2rem' }, // Responsive padding
        },
        contentCard: {
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
            padding: { xs: '1rem', sm: '2rem' }, // Responsive padding
            maxWidth: '1200px',
            margin: '0 auto',
        },
        tabs: {
            marginBottom: { xs: '1rem', sm: '2rem' }, // Responsive margin
            '& .MuiTab-root': {
                fontSize: { xs: '0.9rem', sm: '1.1rem' }, // Responsive font size
                fontWeight: 500,
                minWidth: { xs: '120px', sm: '160px' }, // Responsive width
                borderRadius: '8px 8px 0 0',
                padding: { xs: '6px 12px', sm: '12px 16px' }, // Responsive padding
            },
            '& .Mui-selected': {
                backgroundColor: '#ffffff',
                color: '#1976d2',
            },
        },
        sectionCard: {
            backgroundColor: '#ffffff',
            border: '1px solid #e0e0e0',
            borderRadius: '12px',
            padding: { xs: '1rem', sm: '1.5rem' }, // Responsive padding
            marginBottom: { xs: '1rem', sm: '2rem' }, // Responsive margin
            transition: 'all 0.3s ease',
            '&:hover': {
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
            },
        },
        sectionTitle: {
            fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
            fontWeight: 600,
            color: '#1a1a1a',
            marginBottom: { xs: '1rem', sm: '1.5rem' }, // Responsive margin
        },
        formControl: {
            '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
            },
        },
        actionButton: {
            borderRadius: '8px',
            padding: { xs: '0.5rem 1rem', sm: '0.75rem 2rem' }, // Responsive padding
            fontWeight: 600,
            textTransform: 'none',
        },
    };

    const handleTimeChange = (newValue, setter) => {
        if (newValue && isValid(parseDate(newValue))) {
          const formattedTime = format(parseDate(newValue), "HH:mm:ss");
          setter(formattedTime);
        } else {
          setter(null);
        }
    };

    const [race, setRace] = useState(distanceTriathlonOptions[0].value); // Or initial value
    const handleBikeTimeChange = (newValue) => handleTimeChange(newValue, setBikeTime);
    const handleRunTimeChange = (newValue) => handleTimeChange(newValue, setRunTime);
    {/*
    const handleSexChange = (event) => {
        setSelectedSex(event.value); 
    };
    */}
    const handleWeightChange = (event) => {
        setSelectedWeight(event.target.value); 
    };

    const handleSweatRate = (event) => {
        setSweatRate(event.value); 
    };
    
    const handleEffort = (event) => {
        setEffort(event.value); 
    };

    const handleSaltRate = (event) => {
        setSaltRate(event.value); 
    };

    const handleMiscellaneous = (event) => {
        setMiscellaneous(event); 
    };

    const handleDrinks = (event) => {
        setDrinks(event); 
    };

    const handleBars = (event) => {
        setBars(event); 
    };

    const handleGels = (event) => {
        setGels(event); 
    };
    
    const handleRace = (event) => {
        setRace(event.value); 
    };

    const formatDateToTimeString = (date_str) => {
        if (!date_str) return null;
        const date = new Date(date_str);
        const hours = date.getHours().toString().padStart(2, '0');  // Local hours
        const minutes = date.getMinutes().toString().padStart(2, '0');  // Local minutes
        const seconds = date.getSeconds().toString().padStart(2, '0');  // Local seconds
        return `${hours}:${minutes}:${seconds}`;
    };

    const handleNutritionSubmit = async () => {
        const selectedProducts = [
            ...selectedDrinks,
            ...selectedGels,
            ...selectedBars,
            ...selectedMisc,
        ].map(product => product.value);
    
        // Format selectedProducts as a comma-separated string    
        const formData = {
            sweatRate,
            effort,
            selectedWeight,
            saltRate,
            bikeTime,
            runTime,
            selectedProducts, // Use the formatted string here
            race
        };

        formData.bikeTime = formData.bikeTime === "null" || !(formData.bikeTime instanceof Date) 
            ? formData.bikeTime 
            : formatDateToTimeString(formData.bikeTime);
            formData.runTime = formData.runTime === "null" || !(formData.runTime instanceof Date) 
            ? formData.runTime 
            : formatDateToTimeString(formData.runTime);
         // Constructing the query string
        const queryParams = new URLSearchParams();
            Object.keys(formData).forEach(key => {
                const value = formData[key];
                if (Array.isArray(value)) {
                value.forEach(item => {
                    queryParams.append(key, item);
                });
                } else {
                queryParams.append(key, value);
                }
            });
        const queryString = queryParams.toString();

        try {

            const response = await axios.get(`${apiUrl}/nutrition-plan-html?${queryString}`);
            setApiResponseHtml(response.data); 
            console.log("response: ", response); // Log queryString
            // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            navigate(location.pathname + "?" + queryString, { replace: true });
        } catch (error) {
            console.error("Error during API request:", error);
            setErrorMessage("Error: " + error.message);
        }
    
        console.log("Nutrition form submitted!");
    };

    const [apiResponseHtml, setApiResponseHtml] = useState(""); // Changed to store HTML response

    const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);
   
    const selectStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), // Set a high z-index
    };

    const commonSelectProps = {
    className: "basic-single",
    classNamePrefix: "select",
    isSearchable: true,
    menuPortalTarget: document.body,
    styles: selectStyles,
    };
    
    const handleSubTabChange = (event, subTabIndex) => {
        setCurrentSubTabIndex(subTabIndex);
        setDistanceType("longDistance");
        setBikeTime(formatToHHMMSS());
        setRunTime(formatToHHMMSS());
        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Construct formData here
            const formData = {
                bikeTime,
                runTime,
            };
    
            // Call the first API (handleTriathlonSubmit)
            // await handleTriathlonSubmit(formData); // pass formData if needed
    
            // Call the second API (handleTrainingPlanSubmit)
            // await handleTrainingPlanSubmit(); // pass formData

             // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            const queryParams = new URLSearchParams(linkData).toString();
            navigate(location.pathname + "?" + queryParams, { replace: true });
        } catch (error) {
            console.error("Error during API requests:", error);
            setErrorMessage("Error: " + error.message);
        }
    };

    const downloadNutritionPlanFile = async () => {
        if (!['pdf', 'html'].includes(exportFormat)) {
            setErrorMessage('Please select a format to export.');
            return;
        }
        const selectedProducts = [
            ...selectedDrinks,
            ...selectedGels,
            ...selectedBars,
            ...selectedMisc,
        ].map(product => product.value);
    
        // Format selectedProducts as a comma-separated string    
        const formData = {
            sweatRate,
            effort,
            selectedWeight,
            saltRate,
            bikeTime,
            runTime,
            selectedProducts, // Use the formatted string here
            race
        };

        formData.bikeTime = formData.bikeTime === "null" || !(formData.bikeTime instanceof Date) 
            ? formData.bikeTime 
            : formatDateToTimeString(formData.bikeTime);
            formData.runTime = formData.runTime === "null" || !(formData.runTime instanceof Date) 
            ? formData.runTime 
            : formatDateToTimeString(formData.runTime);

        const queryParams = new URLSearchParams();
            Object.keys(formData).forEach(key => {
                const value = formData[key];
                if (Array.isArray(value)) {
                value.forEach(item => {
                    queryParams.append(key, item);
                });
                } else {
                queryParams.append(key, value);
                }
            });
        const queryString = queryParams.toString();
        try { 
            const response = await axios.get(`${apiUrl}/export-nutrition-file?${queryString}`, {responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `NutritionPlan.${exportFormat}`);
            document.body.appendChild(link);
            link.click();    
        } catch (error) {
            console.error(`Error downloading ${exportFormat} file:`, error);
            setErrorMessage(`Failed to download ${exportFormat} file. Please try again.`);
        }
    };

    const parseDate = (dateString) => {
        if (typeof dateString === 'string') {
          return parseISO(dateString);
        }
        return dateString;
      };

    
    return (
        <Box sx={styles.pageContainer}>
            <Box sx={styles.contentCard}>
                <Tabs 
                value={currentSubTabIndex}
                onChange={handleSubTabChange}
                sx={styles.tabs}
                variant="scrollable" 
                scrollButtons="auto"
                centered
                >
                <Tab 
                    label={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <PoolIcon />
                        <DirectionsBikeIcon />
                        <DirectionsRunIcon />
                        <Typography>Triathlon</Typography>
                    </Stack>
                    }
                />
                <Tab 
                    label={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <DirectionsRunIcon />
                        <Typography>Run</Typography>
                    </Stack>
                    }
                />
                </Tabs>
                
                {currentSubTabIndex === 0 && (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h5" gutterBottom>Triathlon</Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {/* Distance Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h6">Distance</Typography>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={distanceTriathlonOptions[0]}
                                                isSearchable
                                                name="distance"
                                                options={distanceTriathlonOptions}
                                                onChange={handleRace}
                                                menuPortalTarget={document.body}
                                                styles={selectStyles}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>

                                {/* Race Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>

                                        <Typography variant="h6">Race</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Bike Time</Typography>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                    <DirectionsBikeIcon />
                                                    <TimePicker
                                                        label="Time (hh:mm:ss)"
                                                        value={parseTimeToTicks(bikeTime)}
                                                        onChange={handleBikeTimeChange}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        ampm={false} // Disable AM/PM
                                                        views={['hours', 'minutes', 'seconds']}
                                                        format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                    />
                                                    </Stack>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item s={12} md={4} >
                                                <Typography variant="subtitle1">Run Time</Typography>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                    <DirectionsRunIcon />
                                                    <TimePicker
                                                        label="Time (hh:mm:ss)"
                                                        value={parseTimeToTicks(runTime)}
                                                        onChange={handleRunTimeChange}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        ampm={false} // Disable AM/PM
                                                        views={['hours', 'minutes', 'seconds']}
                                                        format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                    />
                                                    </Stack>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Relative Effort</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="effort"
                                                        options={effortOptions}
                                                        onChange={handleEffort}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* Profile Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h6">Profile</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4} >
                                                <Typography variant="subtitle1">Weight</Typography>
                                                <TextField
                                                    label="Weight (kg)"
                                                    value={selectedWeight}
                                                    onChange={handleWeightChange}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4} >
                                                <Typography variant="subtitle1">Sweat Rate</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="sweat"
                                                        options={sweatOptions}
                                                        onChange={handleSweatRate}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Salt Loss Level</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="salt"
                                                        options={sodiumOptions}
                                                        onChange={handleSaltRate}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* Nutrition Options Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>

                                        <Typography variant="h6">Nutrition Options</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4} >
                                                <Typography variant="subtitle1">Drinks</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleDrinks}
                                                    isMulti
                                                    options={drinkOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Gels</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleGels}
                                                    isMulti
                                                    options={gelOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Bars</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleBars}
                                                    isMulti
                                                    options={barOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Miscellaneous</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleMiscellaneous}
                                                    isMulti
                                                    options={miscOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack 
                                        direction={{ xs: 'column', sm: 'row' }} 
                                        spacing={2} 
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Button 
                                            variant="contained" 
                                            onClick={handleNutritionSubmit} 
                                            type="submit" 
                                            sx={{ marginTop: '10px', width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            Generate Plan
                                        </Button>
                                        <Stack 
                                            direction={{ xs: 'column', sm: 'row' }} 
                                            spacing={2} 
                                            alignItems="center"
                                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            <FormControl 
                                                variant="outlined" 
                                                sx={{ 
                                                    width: { xs: '100%', sm: 200 },
                                                    marginTop: { xs: 2, sm: 0 }
                                                }}
                                            >
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={exportOptions[0]}
                                                    isSearchable
                                                    name="export"
                                                    options={exportOptions}
                                                />
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadNutritionPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                                sx={{ 
                                                    width: { xs: '100%', sm: 'auto' },
                                                    marginTop: { xs: 1, sm: 0 }
                                                }}
                                            >
                                                Export
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>

                        {/* Display the API response HTML content */}
                        <div style={{ marginTop: '20px', marginLeft: '50px' }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <div id="message" style={{ color: 'red', marginTop: '20px', marginLeft: '50px' }}>
                            {errorMessage}
                        </div>
                        {/* Display the dynamic link */}
                        {dynamicLink}
                    </Box>
                )}
                {currentSubTabIndex === 1 && (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h5" gutterBottom>Triathlon</Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {/* Distance Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h6">Distance</Typography>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={distanceRunOptions[0]}
                                                isSearchable
                                                name="distance"
                                                options={distanceRunOptions}
                                                onChange={handleRace}
                                                menuPortalTarget={document.body}
                                                styles={selectStyles}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>

                                {/* Race Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>

                                        <Typography variant="h6">Race</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Run Time</Typography>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                    <DirectionsRunIcon />
                                                    <TimePicker
                                                        label="Time (hh:mm:ss)"
                                                        value={parseTimeToTicks(runTime)}
                                                        onChange={handleRunTimeChange}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        ampm={false} // Disable AM/PM
                                                        views={['hours', 'minutes', 'seconds']}
                                                        format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                    />
                                                    </Stack>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Relative Effort</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="effort"
                                                        options={effortOptions}
                                                        onChange={handleEffort}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* Profile Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h6">Profile</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Weight</Typography>
                                                <TextField
                                                    label="Weight (kg)"
                                                    value={selectedWeight}
                                                    onChange={handleWeightChange}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Sweat Rate</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="sweat"
                                                        options={sweatOptions}
                                                        onChange={handleSweatRate}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Salt Loss Level</Typography>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable
                                                        name="salt"
                                                        options={sodiumOptions}
                                                        onChange={handleSaltRate}
                                                        menuPortalTarget={document.body}
                                                        styles={selectStyles}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* Nutrition Options Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>

                                        <Typography variant="h6">Nutrition Options</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Drinks</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleDrinks}
                                                    isMulti
                                                    options={drinkOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Gels</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleGels}
                                                    isMulti
                                                    options={gelOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Bars</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleBars}
                                                    isMulti
                                                    options={barOptions}
                                                />
                                            </Grid>
                                            <Grid item s={12} md={4}>
                                                <Typography variant="subtitle1">Miscellaneous</Typography>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isSearchable
                                                    onChange={handleMiscellaneous}
                                                    isMulti
                                                    options={miscOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack 
                                        direction={{ xs: 'column', sm: 'row' }} 
                                        spacing={2} 
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Button 
                                            variant="contained" 
                                            onClick={handleNutritionSubmit} 
                                            type="submit" 
                                            sx={{ marginTop: '10px', width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            Generate Plan
                                        </Button>
                                        <Stack 
                                            direction={{ xs: 'column', sm: 'row' }} 
                                            spacing={2} 
                                            alignItems="center"
                                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            <FormControl 
                                                variant="outlined" 
                                                sx={{ 
                                                    width: { xs: '100%', sm: 200 },
                                                    marginTop: { xs: 2, sm: 0 }
                                                }}
                                            >
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={exportOptions[0]}
                                                    isSearchable
                                                    name="export"
                                                    options={exportOptions}
                                                />
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadNutritionPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                                sx={{ 
                                                    width: { xs: '100%', sm: 'auto' },
                                                    marginTop: { xs: 1, sm: 0 }
                                                }}
                                            >
                                                Export
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>

                        {/* Display the API response HTML content */}
                        <div style={{ marginTop: '20px', marginLeft: '50px' }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <div id="message" style={{ color: 'red', marginTop: '20px', marginLeft: '50px' }}>
                            {errorMessage}
                        </div>
                        {/* Display the dynamic link */}
                        {dynamicLink}
                    </Box>
                )}
                {/*{currentSubTabIndex === 1 && ()}*/}
            </Box>
        </Box>
    );
}

export default NutritionCalculationPage;