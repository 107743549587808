import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './tri_mate_logo.png'; // Adjust the path as needed
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Box, Grid, Typography, Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';

import HomePage from './HomePage';
import PaceCalculationPage from './PaceCalculationPage';
import TrainingPlanPage from './TrainingPlanPage';
import PoseEstimationPage from './PoseEstimationPage';
import NutritionCalculationPage from './NutritionCalculationPage';

// Update the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Professional blue
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#546e7a',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 500,
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="app-container">
          <Navbar bg="white" expand="lg" className="main-navbar shadow-sm">
            <Container>
              <Navbar.Brand as={Link} to="/" className="brand-container">
                <img
                  src={logo}
                  height="40"
                  className="brand-logo"
                  alt="TriMate Logo"
                />
                <span className="brand-text">TriMate</span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link as={NavLink} to="/" className="nav-item">Home</Nav.Link>
                  <Nav.Link as={NavLink} to="/training-plan" className="nav-item">Training Plan</Nav.Link>
                  <Nav.Link as={NavLink} to="/nutrition-plan" className="nav-item">Nutrition Plan</Nav.Link>
                  <Nav.Link as={NavLink} to="/pace-calculation" className="nav-item">Pace Calculation</Nav.Link>
                  <Nav.Link as={NavLink} to="/pose-estimation" className="nav-item">Pose Estimation</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container fluid>
            <Routes>
              <Route path="/pace-calculation" element={<PaceCalculationPage />} />
              <Route path="/training-plan" element={<TrainingPlanPage />} />
              <Route path="/pose-estimation" element={<PoseEstimationPage />} />
              <Route path="/nutrition-plan" element={<NutritionCalculationPage />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
